@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('./fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('./fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Bold.woff2') format('woff2'),
        url('./fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Light.woff2') format('woff2'),
        url('./fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('./fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Regular.woff2') format('woff2'),
        url('./fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('./fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Italic.woff2') format('woff2'),
        url('./fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@-webkit-keyframes AnimationName {
    0%{background-position:84% 0%}
    50%{background-position:17% 100%}
    100%{background-position:84% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:84% 0%}
    50%{background-position:17% 100%}
    100%{background-position:84% 0%}
}
@keyframes AnimationName {
    0%{background-position:84% 0%}
    50%{background-position:17% 100%}
    100%{background-position:84% 0%}
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

$generalFonts: "Segoe Ui", 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
body, html {
	
	font-family: $generalFonts;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	width: 100%;
	min-height: 100%;

	color: white;
	background: linear-gradient(#0e0021, #1e0047, #05002d, #86162e);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 60s ease infinite;
    -moz-animation: AnimationName 60s ease infinite;
    animation: AnimationName 60s ease infinite;


    

}

img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
	border-radius: 20px;
} 

#main {
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform:translate3d(0,0,0);
    // -webkit-tap-highlight-color: rgba(0,0,0,0);

}

#sidebar {
    -webkit-perspective: unset;
    -webkit-backface-visibility: unset;
    -webkit-transform: unset;
}

.doNotSet {
    -webkit-perspective: unset;
    -webkit-transform: unset;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.vCentre {
    margin: 0;
    position: absolute;
    // top: 43%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    width: 100%;
    text-align: center;
    
    line-height: 1;

    
    // backface-visibility: hidden;

}

.entireCentre {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);


}

#react {
	position: fixed; bottom: 10px; right: 0px;
	img {
        -webkit-animation:spin 15s linear infinite;
        -moz-animation:spin 15s linear infinite;
        animation:spin 15s linear infinite;
        width: 100px;
        position: fixed; bottom: 10px; right: 0px;
    }


}




.centered-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#main {
	padding: 50px;
	border-color: lightgray;
	border-width: 1px;
	border-style: solid;
	max-width: 500px;        
	margin: 0 auto;   
	margin-top: 4vh;  
	margin-bottom: 2vh;
	background-color: #ffffff05;
}



.centre {
	width: 100%;
	text-align: center;
}


#division ul li {
	margin: 5px;
}

#inputbox {
	text-align: right;
	list-style-type:none;
}

#division, #division th, #division td {
	border: 1px solid white;
	border-collapse: collapse;
}

#division {
	width: 100%;
}

#division * ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

#division * td {
	width: 50%;
}

#exerciseresults * td, #exerciseresults * th, #division * td, #division * th {
	padding: 15px;
}

#exerciseresults {
	margin-top: 2vh;
	margin: 0 auto;
	width: 700px;
	border: 1px solid white;
	margin-bottom: 4vh;
}

#daymessage {
	margin-bottom: 0;
	padding-bottom: 0;
}

#exercises {
	padding: 0;
	margin: 0;
	padding-left: 20px;
	/* margin-top: 20px; */
}

#dayMessage {
	margin-top: 10px;
	margin-bottom: 10px;
}

#exercises li {
	padding: 0;
	margin: 0;
}


body {
	max-width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.regimenImage {
	border-color: white;
	border-width: 1px;
	border-style: solid;
}

#headImage {
    width: 600px; 
    margin: 0 auto; 
    padding-top: 40px; 
    display: block; 
    border-radius: 0;
    z-index: -1;
    // left: 0;
}

@media only screen and (max-width: 600px) {

    #react {
        img {
            width: 60px;
        }
    }

    #headImage {
        max-width: 80%;
    }
    

	#division {
		width: 100%;
		
        table-layout: fixed;
        overflow-wrap: break-word;
	}

	#main {
		position: unset;

		top: 0;
		left: 0;
		padding: 20px;
		border-style: none;
		max-width: 100%;
	}

	
    #exerciseresults {
        width: 100vw;
        display: block;
        overflow-x: scroll;
        border: unset;
    }
}




#methods {
    width: 100%;
    margin: 0 auto;
    display: table;
    .method {
        width: 33.33%;
        width: calc(100% / 3);
        margin: 0;
        // display: inline-block;
        // width: 200px;
        display: table-cell;
        float: left;
        display: block;
        border-color: white;
        border-style: solid;
        border-width: 0px;
        
        min-height: 200px;
        box-sizing: border-box;
    }
    
    .methodTitle {
        max-width: 100%;
        font-weight: bold;
        border-color: white;
        border-style: solid;
        border-width: 0px;
        // border-bottom-width: 1px;
        text-align: center;
        padding: 10px;
    }
    .methodContent {
        padding: 20px;
    }
    .method:nth-child(2) {
        width: 180px;
    }
    
    .method:nth-child(3) {
        width: 150px;
    }
    .selectedMethod {
        border-color: unset;
        background-color: #ffffff05;
        outline: 1px solid white;
        outline-offset: -1;
        box-sizing: border-box;
    }
    .method:nth-child(2):not(.selectedMethod) {
        border-left-width: 1px;
        border-right-width: 1px;
    }
    .method:not(.selectedMethod) {
        cursor: pointer;
    }
}

body {
    min-height: 200vh;
}

@media (max-width: 620px) { 

    

    #methods .method {
        
        
        width: 100%;
        display: block;
        min-height: unset;
        border-color: white;
        border-style: solid;
        border-width: 0px;
        border-bottom-width: 0px;
    }
    #methods .methodTitle {
        // border-bottom-width: 0px;
        border-top-width: 0px;
        text-align: center;
        border-bottom-width: 1px;
    }
    #methods .method:nth-child(2) {
        width: 100%;
        border-left-width: 0px;
        border-right-width: 0px;
    }
    
    #methods .method:nth-child(1) {
        width: 100%;
    }
    #methods .method:nth-child(2):not(.selectedMethod) {
        border-left-width: 0;
        border-right-width: 0;
    }
    #methods .method:nth-child(3) {
        width: 100%;
    }   
}

#table{ 
    margin: 0 auto;
    display: table;
    width: 100%;
}
.tr { 
    display: table-row; 
}

.td:nth-child(2):not(.selected) {
    border-color: white;
    border-width: 1px;
    border-style: solid;
    border-top-style: unset;
    border-bottom-style: unset;
}
.td { 
    display: table-cell; 
    width: 33%;
    padding: 12px;
}

.titles {
    font-weight: bold;
    text-align: center;
}

.selected {
    background-color: #ffffff05;
    border: 1px solid white;
    
    border-top-width: 0;
}

.titles .selected {
    
    border-top-width: 1px;
}

.titles .selected {
    // border-top-style: unset;
    
    border-bottom-style: unset;
}

.td:nth-child(1).selected {
    border-right-style: unset;
}

.td:nth-child(3).selected {
    border-left-style: unset;
}

.hidden {
    display: none;
    font-weight: bold;
    font-size: 12pt;
    font-style: italic;
    text-align: center;
    margin-top: 10px;
}

.hide {
    display: none;
}

#accountCreate {
    margin: 0 auto;
    width: 300px;
}

.daySelect, .socialExercise, #division {
    button {
        display: inline-block;
        width: 50%;
        // height: 50px;
    }
}

@media (max-width: 620px) { 

    #exerciseEditor {
        margin-top: 2vh;
        margin: 0 auto;
        width: 500px;
        max-width: 100%;
        border: unset;
        margin-bottom: 4vh;
        td, th {
            padding: 10px;
        }
    }

    .daySelect, .socialExercise {
        button {
            display: block;
            width: 100%;
            height: 50px;

            color: white;
            background-color: #ffffff20;
            border: 1.5px solid white;
            margin-top: 3px;
            border-radius: 6px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

        }
    }

    .hide {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
    }
    .hidden {
        display: block;
    }
    .td {
        display: inline-block;
        width: 100%;
        // min-height: 120px;
        padding: 20px;
        border: unset;
        box-sizing: border-box;
    }
    .td:nth-child(2):not(.selected) {
        border: unset;
    }
    

    .titles {
        display: none;
    }
    .selected {
        outline: 1px solid white;
    }
    
    .td:nth-child(3).selected {
        border-left-style: none;
    }

    .td:nth-child(1).selected {
        border-right-style: none;
    }

    .td:nth-child(2):not(.selected) {
        border: 1px solid white;
        border-left-style: none;
        border-right-style: none;
    }

    .td:nth-child(2) {
        border: 1px solid #00000000;
        border-left-style: none;
        border-right-style: none;
    }
    
    .tr {
        max-width: 20%;
    }

    #accountCreate {
        margin: 0 auto;
        width: 90%;
    }
}

.methodSection:not(.selected) {
    cursor: pointer;
}

a {
    color: white;
}

$navRadius: 10px;

#nav {
    margin-top: 15px;
    #switchImage {
        list-style-type: none;
        margin: 0;
        padding: 0;
    
        display: inline-block;
        width: auto;
        // background-color: white;
        color: white;
        font-weight: bold;
        border-radius: 10px;
    
    
        border-color: white;
        border-style: solid;
        border-width: 1px;
    
        li {
            float: left;
            border-right: 2px solid white;
        
        }
    
        li:last-child {
            border-right: none;
        }
    
        li .a {
            display: block;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
        }
    
        li .a:hover:not(.active) {
            background-color: #ffffff15;
            cursor: pointer;
        
        }
    
        li:first-child .a:hover:not(.active) {
        
            border-radius: 10px 0px 0px 10px;
        
        }
    
        li:last-child .a:hover:not(.active) {

            border-radius: 0px 10px 10px 0px;
        
        }
    
        .active {
            background-color: white;
            color: black;
            cursor: default;
        }
    
        li:first-child .active {
            border-top-left-radius: $navRadius;
            border-bottom-left-radius: $navRadius;
        }
    
        li:last-child .active {
            border-radius: 0px 10px 10px 0px;
        }

        li:only-child .active {
            border-radius: 10px 10px;
            background-color: unset;
            font-style: italic;
            color: white;
            cursor: default;
        }
    }  
}

.ownExercise {
    input {
        width: 50px;
        background-color: #ffffff00;
        color: white;
        border: none;
        font-family: $generalFonts;
        font-size: unset;
    }   
    
    select {
        font-size: unset;
        width: auto;
        padding: 2px;
    }

    button {
        display: inline;
    }

}

#exerciseEditor {
    margin-top: 2vh;
	margin: 0 auto;
	width: 730px;
    max-width: 100%;
	border: 1px solid white;
	margin-bottom: 4vh;
    td, th {
        padding: 20px;
    }
    overflow-x: scroll;
}


@media (max-width: 620px) { 

    #exerciseEditor {
        display: block;
        max-width: 100vw;
        margin: unset;
        border: unset;
        td, th {
            padding: 5px;
        }
    }
}
.textChange {
    text-shadow: 3px 3px #00000020;
}

.textChange:nth-child(1) {
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-direction: alternate-reverse;
}
  
.textChange:nth-child(2){
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-direction: alternate;   
}
  
@keyframes fade {
    0%, 50% {
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}



#myExercise {

    border: 1px solid white;
    z-index: 5; 
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    position: relative;
    cursor: unset;

    .exerciseCover {
        font-size: 18pt;
        font-weight: bold;
        
        -webkit-transform: unset !important;
    }

    button {
        width: unset;
    }

    margin-top: 20px;

    height: 200px;

}

#myExercise.notStarted {
    cursor: pointer;
}   

#myExercise.hasStarted {
    height: unset;
    min-height: 200px;
} 

$textBlur: 6px;


#cover {
    color: #ffffff80;
    -webkit-filter: blur($textBlur);
    -moz-filter: blur($textBlur);
    -o-filter: blur($textBlur);
    -ms-filter: blur($textBlur);
    filter: blur($textBlur);
    // position: fixed;
    font-size: 12pt;
    max-height: 200px;
    touch-action: none;
    z-index: -1;
    overflow: hidden;
    position: relative;
    padding: 20px;
}

$otherBlur: 8px;
.blur {
    -webkit-filter: blur($otherBlur);
    -moz-filter: blur($otherBlur);
    -o-filter: blur($otherBlur);
    -ms-filter: blur($otherBlur);
    filter: blur($otherBlur);
}

.myExerciseList {
    input[type="radio"] {
        margin-right: 5px;
    }
}

.navBar {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
    }
    li {
        display: inline;
        margin: 0;
        margin-right: 20px;
    }
    a {
        color: white;
        text-decoration: none;
    }
    .active {
        // font-weight: bold;
        text-decoration: underline;
    }

}

@media only screen and (max-width: 600px) {
    
    .navBar {
        .active {
            font-weight: bold;
            text-decoration: unset;
        }
        ul {
            // list-style-type: initial;
            margin: unset;
            padding: unset;
        }
        li {
            display: list-item;
        }
        .hideOnMobile {
            display: none;
        }
    }
    
}

#root {
    margin: 0;
}

.hideshow {
    float: right;
}


#sidebar {

    .sBNavLink {
        width: 100%;
        display: block;
    }

    .scrollButton {
        transition: all .5s;
        cursor: pointer;
    }

    .sessiond {
        font-weight: bold;
    }
    

    float: left;
    width: 200px;
    max-width: 200px;
    position: fixed;
    background-color: #00000030;
    // height: 100%;

    margin: 0;
    top: 0;
    left: 0;

    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;

    transition: margin .5s;
    
    span, div {
        transition: opacity .5s;
    }

    ul {
        padding-left: 30px;
        list-style: none;
        opacity: 1;
        transition: opacity .5s;
        .active {
            font-weight: bold;
        }
        li {
            margin-bottom: 5px;
            
        }
        a {
            text-decoration: none;
        }
    }

    #clickMe {
        display: none;    
    }

    button.hideShow {
        background-color: #00000060;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        float: right;
        border: 1px solid #ffffff40;
    }

    button.hideShow:hover {
        background-color: #ffffff40;
        border: 1px solid #00000060;
    }
}

#mobileSelect {
    display: none;
}

#sidebar.moved {
    margin-left: -140px;
    ul, span, div {
        opacity: 0;
    }
    
}

.mobile {
    display: none;
}

.desktop {
    display: inline-block;
}

@media only screen and (max-width: 1012px) {
    
    
    

    #mobileSelect, .hideShow {
        cursor: pointer;
        display: block;
        float: right;
        position: fixed;
        // height: 100%;

        margin: 0;
        top: 0;
        right: 0;

        z-index: 1000;
        padding: 20px;
        
        box-sizing: border-box;

        
        
        transition: margin .5s;
    }

    

    #sidebar.moved {
        ul {
            opacity: 1;
        }
    }

    #mobileSelect {
        background-color: #00000090;
        margin-right: -200px;
    }

    #mobileSelect.show {
        margin-right: 0;
    }

    #sidebar {
        width: 100%;
        max-width: 100%;
        margin: 0;
        transform:translateY(0);
        transition: transform .5s;
        backdrop-filter: blur(10px);
        background-color: #00000099;
        border-bottom: 2px solid #ffffff40;
    }

    #sidebar.moved {
        margin-left: 0;
        transform:translateY(calc(-100%));
    }

    .mobile {
        display: inline-block;
    }

    .desktop {
        display: none;
    }
    
}

#friends {
    border: 2px solid #ffffff90;
    min-height: 100px;
}

.social {
    ul {
        li {
            cursor: pointer;
        }
    }
}

// #main {
//     // float: left;
// }


// #mainSideBar {
//     background-color: #00000090;
//     min-height: 300px;
//     min-width: 200px;
//     display: inline-block;
//     float: right;
// }


.imageMessage {
    opacity: 0;
}


#profileSplit {
    // border: 1px solid white;
    float: left;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    margin-top: 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow-x: auto;
    background-color: #ffffff05;

    .split1 {
        width: 30%;
        float: left;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .split2 {
        width: 70%;
        float: right;
        padding: 20px;
        box-sizing: border-box;
    }
}

#profileEditText {
    float: left;
    text-align: right;
    width: 100%;
    margin-bottom: 10px;
}

#profileChange {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        margin-bottom: 10px;
        list-style: none;
        padding: 0;
        
    }
}

.group {
    .special.administration {
        .formalName {
            font-variant: small-caps;
        }
    }
    
    .groupInfo {
        img.groupImage {
            width: 45%;
        }
        .formalName {
            margin: 0;
        }
        .name {
            font-style: italic;
        }

        .userListHeader {
            margin-top: 10px;
            display: block;
        }

        .userList {
            margin: 0;
        }
    }
}

.sessions {
    .connectedUsers, .requestedUsers {
        .user::after {
            content: " ";
            white-space: pre;
        }

        .user:last-child::after {
            content: unset;
            white-space: initial;
        }
    }
}

.fakeLink {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.fakeLink:hover {
    // font-weight: bold;
}